import axios from 'axios';
import { getToken } from './authService';
const BASE_URL = process.env.REACT_APP_BASE_URL;
// const DEVELOPMENT = process.env.NODE_ENV === 'development';

const { token, id, _token } = getToken();

const axiosInstance = axios.create({
	baseURL: BASE_URL,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		Authorization: token ? `Bearer ${token}` : null,
		's-store-id': id,
		'X-CSRF-Token': _token,
	},
});

axiosInstance.interceptors.response.use((response) => {
	if (response.status >= 400) {
		localStorage.removeItem('token');
		window.parent.postMessage('getFreshToken', '*');
	}
	return response;
});

export default axiosInstance;
