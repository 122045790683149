import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import HomeView from './views/HomeView';
import './index.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import NoMatch from './views/NoMatch';

const basename = process.env.NODE_ENV === 'production' ? '/include/product-certificates' : '';
const router = createBrowserRouter(
	[
		{
			path: '/',
			element: <HomeView />,
		},
		{
			path: '*',
			element: <NoMatch />,
		},
	],
	{ basename },
);

createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<Provider store={store}>
			<div className="">
				<RouterProvider router={router} />
			</div>
		</Provider>
	</React.StrictMode>,
);
