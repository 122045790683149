import React from 'react';
import './Card.scss';
import classNames from 'classnames';

type Children = {
	children: React.ReactNode;
};

type CardProps = {
	children: React.ReactNode;
	onClick?: () => void;
	style?: React.CSSProperties;
	className?: string;
};

const Image = ({ src, alt }: { src: string; alt: string }) => {
	return <img src={src} alt={alt} className="card__image__img h-[333px] w-full" />;
};

const Overlay = ({ children }: Children) => {
	return <div className="card__overlay">{children}</div>;
};

const Body = ({ children }: Children) => {
	return <div className="card__body ">{children}</div>;
};

const Actions = ({ children }: Children) => {
	return <div className="card__actions">{children}</div>;
};

const Title = ({ children }: Children) => {
	return <h4 className="card__tile">{children}</h4>;
};

const Description = ({ children }: Children) => {
	return <p className="card__description">{children}</p>;
};
function Card({ children, onClick, className, style }: CardProps) {
	const cardClasses = classNames('card', className);
	const handleClick = () => {
		onClick && onClick();
	};
	return (
		<>
			<div className={cardClasses} onClick={handleClick} style={style}>
				{children}
			</div>
		</>
	);
}

export default Card;

Card.Image = Image;
Card.Overlay = Overlay;
Card.Body = Body;
Card.Actions = Actions;
Card.Title = Title;
Card.Description = Description;
