import * as React from 'react';

import UIModal from './UI/Modal/UIModal';
import {
	addTemplateAsync,
	editTemplateAsync,
	getProductsAsync,
	getStoreTemplatesAsync,
	getSystemTemplatesAsync,
} from '../features/templates/TemplateReducers';
import { setActiveTemplate, setSelectedProducts } from '../features/templates/TemplatesSlice';
import ProductsForm from './ProductsForm';
import { useAppDispatch } from '../app/store';
import { type Template } from '../types/templates';
import UILoader from './UI/UILoader';
import useSwal from '../hooks/useSwal';
import { useActiveTemplate } from '../hooks/redux';
import TemplateCard from './TemplateCard';

interface Props {
	templates: Template[];
	children?: React.ReactNode;
}

const CardsWrapper = ({ templates }: Props) => {
	const [showModal, setShowModal] = React.useState(false);
	const [status, activeTemplate] = useActiveTemplate();
	const { $error } = useSwal();
	const dispatch = useAppDispatch();
	const showAllCards = () => {
		dispatch(getStoreTemplatesAsync({ limit: '' }));
	};

	const showEditModal = (template: Template) => {
		dispatch(setActiveTemplate(template));
		setShowModal(true);
	};

	const updateTemplate = () => {
		if (!activeTemplate) return;
		if (!activeTemplate.products_ids || activeTemplate.products_ids.length === 0) {
			$error({ text: 'يجب إختيار منتج واحد على الأقل' });
			return;
		}
		if (activeTemplate.type === 'suggested') {
			dispatch(addTemplateAsync({ template: activeTemplate })).then(() => {
				dispatch(getProductsAsync()).then(() => {
					dispatch(getSystemTemplatesAsync());
				});
			});
		} else {
			dispatch(editTemplateAsync({ template: activeTemplate })).then(() => {
				dispatch(getProductsAsync());
			});
		}

		setShowModal(false);
	};

	const cancelUpdate = () => {
		dispatch(setActiveTemplate(null));
		dispatch(setSelectedProducts(null));
	};

	return (
		<div className="flex flex-wrap gap-6 justify-start items-center p-5 relative ">
			{templates.length === 0 && status == 'idle' && (
				<h1 className="mx-auto text-dark-100 text-lg">لا يوجد نماذح</h1>
			)}
			{templates.length === 0 && <div className="h-32">{status === 'loading' && <UILoader />}</div>}
			{templates.map((template) => (
				<TemplateCard key={template.id} template={template} handleClick={showEditModal} />
			))}
			{templates.length == 8 && <TemplateCard overlay template={templates[7]} handleClick={showAllCards} />}
			<UIModal
				saveModal={updateTemplate}
				closeModal={cancelUpdate}
				showModal={showModal}
				setShowModal={setShowModal}
				dialogTitle="إضافة القالب"
				saveButtonText="حفظ"
				cancelButtonText="إلغاء"
			>
				<ProductsForm />
			</UIModal>
		</div>
	);
};

export default CardsWrapper;
