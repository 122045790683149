import React from 'react';
import UIDropdown from './UI/Dropdown/UIDropdown';
import { deleteTemplateAsync, getProductsAsync, getSystemTemplatesAsync } from '../features/templates/TemplateReducers';
import useSwal from '../hooks/useSwal';
import { useAppDispatch } from '../app/store';
import { Template } from '../types';

type Props = {
	template: Template;
	cb: (template: Template) => void;
};

const TemplateActionsDropdown = ({ cb, template }: Props) => {
	const { $confirm } = useSwal();
	const dispatch = useAppDispatch();
	const dropdownOptions = [
		{
			text: 'تعديل',
			icon: 'sicon-edit',
			onClick: () => cb(template),
			color: 'dark:text-dark-100',
			hoverColor: 'text-dark-500',
		},
		{
			text: 'حذف',
			icon: 'sicon-trash',
			onClick: () => deleteTemplate(),
			color: 'text-red-500',
			hoverColor: 'text-red-600',
		},
	];
	const deleteTemplate = () => {
		$confirm({
			title: 'تأكيد حذف القالب',
			text: 'في حال تأكيد الحذف لن تستطيع استرجاع إعدادات التخصيص المدرجة على القالب',
			cancelButtonText: 'إلغاء',
			confirmButtonText: 'حذف',
		}).then(async (result) => {
			if (result.isConfirmed) {
				dispatch(deleteTemplateAsync({ template })).then(() => {
					dispatch(getProductsAsync()).then(() => {
						dispatch(getSystemTemplatesAsync());
					});
				});
			}
		});
	};
	return (
		<div className="w-8 h-8 flex items-center justify-center">
			<UIDropdown options={dropdownOptions} />
		</div>
	);
};

export default TemplateActionsDropdown;
