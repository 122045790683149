import React from 'react';
import { Template } from '../types';
import TemplateActionsDropdown from './TemplateActionsDropdown';
import ToggleActivateTemplate from './ToggleActivateTemplate';
import Card from './UI/Card/Card';
type Props = {
	template: Template;
	handleClick: (template: Template) => void;
	overlay?: boolean;
};
import classNames from 'classnames';

function TemplateCard({ template, handleClick, overlay }: Props) {
	const { name, main_text, thumbnail, type } = template;

	return (
		<>
			<Card
				onClick={() => {
					template.type === 'suggested' && handleClick(template);
				}}
				className={classNames({ 'cursor-pointer': type === 'suggested' })}
			>
				{overlay && (
					<Card.Overlay>
						<button
							className="bg-secondary-50 text-primary-l font-medium py-2 px-4 rounded-2xl flex gap-2 justify-center items-center"
							onClick={() => {
								handleClick(template);
							}}
						>
							<span className="sicon-eye"></span>
							عرض كل النماذج
						</button>
					</Card.Overlay>
				)}
				<Card.Image src={thumbnail} alt="template" />
				<Card.Body>
					{type === 'suggested' && !overlay && (
						<div className="p-4">
							<Card.Title>{name}</Card.Title>
							<Card.Description>{main_text}</Card.Description>
						</div>
					)}

					{type === 'store' && !overlay && (
						<Card.Actions>
							<TemplateActionsDropdown template={template} cb={handleClick} />
							<ToggleActivateTemplate template={template} />
						</Card.Actions>
					)}
				</Card.Body>
			</Card>
		</>
	);
}

export default TemplateCard;
