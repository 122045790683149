import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import './style.scss';
import templateData from './data.json';
import { usePDF } from 'react-to-pdf';
import api from '../services/api';
import { CHECK_TEMPLATE, ORDERS } from '../endpoints';
import { ErrorBoundary } from 'react-error-boundary';
import { fallbackRender } from '../views/HomeView';

export type orderData = {
	customer: string;
	store_name: string;
	amount: string;
	logo_image: string;
	peoduct_name: string;
};
const TemplatesPreview = ({ id }: { id: string }) => {
	const [searchParams] = useSearchParams();
	const [orderData, setOrderData] = React.useState<orderData>({
		customer: '',
		store_name: '',
		amount: '',
		logo_image: '',
		peoduct_name: '',
	});
	const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });
	const staticData = React.useMemo(() => {
		switch (id) {
		case '1':
			return templateData.template_one;
		case '2':
			return templateData.template_two;
		case '3':
			return templateData.template_three;
		default:
			return templateData.template_one;
		}
	}, [id]);

	const preparePage = () => {
		document.body.style.height = '1100px';
		document.body.style.width = '793px';
		document.querySelector('html')?.classList.add('flex');
		document.querySelector('html')?.classList.add('justify-center');
		document.querySelector('html')?.classList.add('items-center');
	};
	const getTemplateDetails = async (order_id: string, storeId: string) => {
		const { data } = await api.get(`${ORDERS}/${order_id}?certificateId=${id}`, {
			headers: {
				Accept: 'application/json',
				's-store-id': storeId,
			},
		});
		// const { data } = await api.get(`${ORDERS}/${order_id}`, config);
		if (data.success) {
			setOrderData(data);
			toPDF();
		}
	};
	const checkTemplate = async (id: string) => {
		try {
			const { data } = await api.get(CHECK_TEMPLATE + '/' + id);
			return data.success;
		} catch (error) {
			console.log(error);
			return false;
		}
	};

	React.useEffect(() => {
		if (id) {
			preparePage();
			checkTemplate(id).then((res) => {
				console.log(res);
				if (res) {
					const order_id = searchParams.get('order_id');
					const store_name = searchParams.get('store_name');
					if (order_id && store_name) {
						getTemplateDetails(order_id, store_name);
					}
				}
			});
		}
	}, [id]);

	return (
		<ErrorBoundary fallbackRender={fallbackRender}>
			<div className={`template-${id}`} ref={targetRef}>
				<div className="template" style={{ backgroundImage: `${staticData.background}` }}>
					<div className="template__wrapper">
						{/* Header */}
						<div className="template__header">
							<img className="template__header__logo" src={orderData.logo_image} alt="logo" />
							{/* store name */}
							<h1 className="template__header__title">{orderData.store_name}</h1>
							<div
								className="template__header__image"
								style={{ backgroundImage: `${staticData.header.header_image}` }}
							>
								{' '}
							</div>
						</div>
						{/* end of header */}

						{/* Content */}
						<div className="template__content">
							<div className="template__content__heading">
								<span className="title">{staticData.body.title}</span>
								<span className="subtitle">{staticData.body.subtitle}</span>
							</div>
							<div className="template__content__main">
								<div className="template__content__heading">
									<span className="subtitle">تشهد الجمعية بتبرع</span>
									<span className="customer">{orderData.customer}</span>
								</div>
								<div className="template__content__heading">
									<span className="subtitle">مبلغ التبرع</span>
									<span className="amount">{orderData.amount}</span>
								</div>
							</div>
						</div>
						{/* end of content */}

						{/* footer */}
						<div className="template__footer">
							{staticData.footer.image && (
								<div
									className="template__footer__image"
									style={{ backgroundImage: `${staticData.footer.image}` }}
								/>
							)}
							<h3 className="title">{orderData.peoduct_name}</h3>
							<p className="subtitle">{staticData.footer.subtitle}</p>
						</div>
						{/* end of footer */}
					</div>
				</div>
			</div>
		</ErrorBoundary>
	);
};

export default TemplatesPreview;
