import React from 'react';
import UISwitch from './UI/UISwitch';
import { activateTemplateAsync } from '../features/templates/TemplateReducers';
import { Template } from '../types';
import { useAppDispatch } from '../app/store';

const ToggleActivateTemplate = ({ template }: { template: Template }) => {
	const { is_active } = template;
	const [checked, setChecked] = React.useState<boolean>(is_active);
	const dispatch = useAppDispatch();
	const activateTemplate = (status: boolean | ((prevState: boolean | number) => boolean)) => {
		setChecked(status);
		dispatch(activateTemplateAsync({ template }));
	};
	return <UISwitch onChange={activateTemplate} checked={checked} />;
};

export default ToggleActivateTemplate;
